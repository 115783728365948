<script>


/**
 * Commission Edit component
 */


import {
    required,
} from "vuelidate/lib/validators";
import AgentAutoComplete from '@/components/agent-auto-complete-input'

import { financial } from '@/api/misc'
import { getDealBasicApi } from '@/api/deal/basic'

export default {

    emits: ['confirm', 'cancel'],

    props: {
        deal: {
            type: Object,
            default: () => ({
                listing_brokers: [],
                selling_brokers: []
            })
        },
    },

    components: {
        //flatPickr
        AgentAutoComplete,
    },

    validations: {

       

        listing_brokers: {
            $each: {
                name: { required },
                commission: { required },
                gst: { required },

            }
        },

        selling_brokers: {
            $each: {
                name: { required },
                commission: { required },
                gst: { required },

            }
        }
        
    },



    data() {
        return {
            delete_brokers: [],

            listing_brokers : [],
            selling_brokers : [],
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {

                let brokers = [...this.listing_brokers, ...this.selling_brokers]
                getDealBasicApi().edit_brokers(this.deal.order_deal_id, brokers, this.delete_brokers).then(res => {
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Failed to Update " + res.msg);
                    }
                })

            }
        },



        onSelectedAgent(selected_agent,  broker) {

            broker.agent_name  = selected_agent.agent.first_name +' '+ selected_agent.agent.last_name
            broker.name        = selected_agent.agent.brokerage.name
            broker.brokerage   = selected_agent.agent.brokerage.name
            broker.first_name  =  selected_agent.agent.first_name
            broker.last_name   =  selected_agent.agent.last_name

        },

        delete_broker(type, broker, idx) {
            if (type == 'LISTING') {
                this.listing_brokers.splice(idx, 1)
            } else if (type == 'SELLING') {
                this.selling_brokers.splice(idx, 1)
            }

            if (broker.is_new != true) {
                this.delete_brokers.push(broker)
            }
        },

        add_new_broker(type) {
            if (type == 'LISTING') {
                this.listing_brokers.push({
                    type : 'Listing Broker',
                    name: '',
                    agent_name: '',
                    agent_vin: '',
                    commission: '',
                    gst: '',
                    is_new : true,
                })
            } else if (type == 'SELLING') {
                this.selling_brokers.push({
                    type : 'Selling Broker',
                    name: '',
                    agent_name: '',
                    agent_vin: '',
                    commission: '',
                    gst: '',
                    is_new : true,
                })
            }
        },


        watch_commission(evt, broker) {
            if (Number.isNaN(evt.target.value)) {
                return
            }
            broker.gst = financial(Number(evt.target.value) * 0.05, 2)
            broker.total = financial(Number(broker.commission) + Number(broker.gst), 2)
        },


        watch_commission_gst(evt, broker) {

            if (Number.isNaN(evt.target.value)) {
                return
            }
            broker.total = financial(Number(broker.commission) + Number(broker.gst), 2)
        },


    },

    created() {

    },

    mounted() {

        this.listing_brokers.push(...this.deal.listing_brokers)
        this.selling_brokers.push(...this.deal.selling_brokers)

        this.listing_brokers.map(e => {
            let agent = this.deal.agents.find(a => a.agent_vin == e.agent_vin)
            if (agent) {
                e.in_house   = true
                e.agent_type = agent.agent_type
                e.agent_id   = agent.agent_id

                let sub_deal = this.deal.links.find(d => d.bind_agent_id == e.agent_id && e.agent_type == d.agent_type)
                if (sub_deal) {
                    e.sub_deal_id = sub_deal.order_deal_no
                }
            }
        })


        this.selling_brokers.map(e => {
            let agent = this.deal.agents.find(a => a.agent_vin == e.agent_vin)
            if (agent) {
                e.in_house   = true
                e.agent_type = agent.agent_type
                e.agent_id   = agent.agent_id

                let sub_deal = this.deal.links.find(d => d.bind_agent_id == e.agent_id && e.agent_type == d.agent_type)
                if (sub_deal) {
                    e.sub_deal_id = sub_deal.order_deal_no
                }
            }
        })
    }

};
</script>

<template>
    <div class="row">

        <form class="needs-validation" @submit.prevent="formSubmit">

            <div class="card mb-0">
                <div class="card-header">

                    <div class="row">
                        <div class="col-sm-2">
                            <label class="form-label font-size-20">Listing</label>
                        </div>
                        <div class="col-sm-10 text-end">
                            <b-button variant="primary" type="button" @click="add_new_broker('LISTING')">Add Listing
                                Broker</b-button>
                        </div>
                    </div>
                </div>


                <div class="card-body pb-5">
                    <div class="row mb-3" v-for="(broker, idx) in listing_brokers" :key="'listint_broker_' + idx">

                        <div class="col-sm-4">
                            <label class="form-label">Agent</label>
                            <AgentAutoComplete ref="agent_name_ref" :agent_idx="0" v-model="broker.agent_name"
                                :init_data="{ user_obj : broker, agent_name: broker.agent_name, agent_id: broker.agent_id, style_class: { 'form-control': 'form-control', 'is-invalid': $v.listing_brokers.$each[idx].name.$error } }"
                                @onSelected="onSelectedAgent($event, broker)" autocomplete="off"
                                :class="{ 'is-invalid': $v.listing_brokers.$each[idx].$error }" />
                            <div v-if="$v.listing_brokers.$each[idx].name.$error" class="invalid-feedback">
                                <span v-if="$v.listing_brokers.$each[idx].name.$error">This value is
                                    required.</span>
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <label class="form-label">Commission</label>
                            <input type="text" class="form-control" v-model="broker.commission"
                                :class="{ 'is-invalid': $v.listing_brokers.$each[idx].commission.$error }"
                                @input="watch_commission($event, broker)" @blur="watch_commission($event, broker)" />
                            <div v-if="$v.listing_brokers.$each[idx].commission.$error" class="invalid-feedback">
                                <span v-if="$v.listing_brokers.$each[idx].commission.$error">This value is
                                    required.</span>
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <label class="form-label">GST</label>
                            <input type="text" class="form-control" v-model="broker.gst"
                                :class="{ 'is-invalid': $v.listing_brokers.$each[idx].gst.$error }"
                                @input="watch_commission_gst($event, broker)"
                                @blur="watch_commission_gst($event, broker)" />
                            <div v-if="$v.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                                <span v-if="$v.listing_brokers.$each[idx].gst.$error">This value is
                                    required.</span>
                            </div>
                        </div>
                        <div class="col-sm-2 mb-3">
                            <label class="form-label">Total</label>
                            <input type="text" class="form-control" v-model="broker.total" readonly />
                        </div>

                        <div class="col-sm-1 mt-4">
                            <b-button variant="danger" type="button"
                                @click="delete_broker('LISTING', broker, idx)">Delete</b-button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card mb-0 mt-3">

                <div class="card-header">

                    <div class="row">
                        <div class="col-sm-2">
                            <label class="form-label font-size-20">Selling</label>
                        </div>
                        <div class="col-sm-10 text-end">
                            <b-button variant="primary" type="button" @click="add_new_broker('SELLING')">Add Selling
                                Broker</b-button>
                        </div>
                    </div>
                </div>


                <div class="card-body pb-5">
                    <div class="row mb-3" v-for="(broker, idx) in selling_brokers" :key="'selling_broker_' + idx">

                        <div class="col-sm-4">
                            <label class="form-label">Agent</label>
                            <AgentAutoComplete ref="agent_name_ref" :agent_idx="0" v-model="broker.name"
                                :init_data="{ agent_name: broker.agent_name, agent_id: broker.agent_id, style_class: { 'form-control': 'form-control', 'is-invalid': $v.selling_brokers.$each[idx].name.$error } }"
                                @onSelected="onSelectedAgent($event, broker)" autocomplete="off"
                                :class="{ 'is-invalid': $v.selling_brokers.$each[idx].$error }" />
                            <div v-if="$v.selling_brokers.$each[idx].name.$error" class="invalid-feedback">
                                <span v-if="$v.selling_brokers.$each[idx].name.$error">This value is
                                    required.</span>
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <label class="form-label">Commission</label>
                            <input type="text" class="form-control" v-model="broker.commission"
                                :class="{ 'is-invalid': $v.selling_brokers.$each[idx].commission.$error }"
                                @input="watch_commission($event, broker)" @blur="watch_commission($event, broker)" />
                            <div v-if="$v.selling_brokers.$each[idx].commission.$error" class="invalid-feedback">
                                <span v-if="$v.selling_brokers.$each[idx].commission.$error">This value is
                                    required.</span>
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <label class="form-label">GST</label>
                            <input type="text" class="form-control" v-model="broker.gst"
                                :class="{ 'is-invalid': $v.selling_brokers.$each[idx].gst.$error }"
                                @input="watch_commission_gst($event, broker)"
                                @blur="watch_commission_gst($event, broker)" />
                            <div v-if="$v.selling_brokers.$each[idx].gst.$error" class="invalid-feedback">
                                <span v-if="$v.selling_brokers.$each[idx].gst.$error">This value is
                                    required.</span>
                            </div>
                        </div>
                        <div class="col-sm-2 mb-3">
                            <label class="form-label">Total</label>
                            <input type="text" class="form-control" v-model="broker.total" readonly />
                        </div>

                        <div class="col-sm-1 mt-4">
                            <b-button variant="danger" type="button"
                                @click="delete_broker('SELLING', broker, idx)">Delete</b-button>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row mt-3">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">
                    <div class="d-flex flex-wrap gap-2">
                        <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                        <b-button variant="primary" type="submit">Submit</b-button>
                    </div>
                </div>
                <div class="col-md-4">

                </div>

            </div>
            <!-- end row-->

        </form>

    </div>
</template>