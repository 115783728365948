


import request from '@/utils/request';


class DealBasicApi {

    create_new_deal = (data) => {
        return request({
            url: '/adm/deal/basic/setup_new_deal',
            method: 'post',
            data
        });	
        
    }

    detail = (data) => {

        return request({
            url: '/adm/deal/basic/deal_detail',
            method: 'post',
            data
        });	
    }


    get_new_deal_no = () => {
        return request({
            url: '/adm/deal/basic/get_new_deal_no',
            method: 'post',
           
        });	
        
    }

    get_deal_type_list = () => {
        return request({
            url: '/adm/deal/basic/get_deal_type',
            method: 'post',
           
        });	
        
    }

    query_agent_commission = (data) => {
        return request({
            url: '/adm/deal/basic/query_deal_agent_commission',
            method: 'post',
            data
        });	
    }


    modify_deal = (data) => {
        return request({
            url: '/adm/deal/basic/modify_deal',
            method: 'post',
            data
        });	
    }

    deal_unwind = (data) => {
        return request({
            url: '/adm/deal/basic/delete_deal',
            method: 'post',
            data
        });	
        
    }

    deal_logs = (data) => {
        return request({
            url: '/adm/deal/basic/deal_logs',
            method: 'post',
            data
        });	
        
    }

    delete_sub_deal = (sub_deal_id) => {
        let data = {
            sub_deal_id : sub_deal_id
        }
        return request({
            url: '/adm/deal/basic/delete_sub_deal',
            method: 'post',
            data
        });	

    }

    add_sub_deal = (main_deal_no, sub_deal_commission) => {
        let data = {
            main_deal_id : main_deal_no,
            sub_deal     : sub_deal_commission
        }

        return request({
            url: '/adm/deal/basic/add_sub_deal',
            method: 'post',
            data
        });	
    }

    new_sub_deal_no = (main_deal_no) => {
        let data = {
            main_deal_no : main_deal_no
        }

        return request({
            url: '/adm/deal/basic/get_new_sub_deal_no',
            method: 'post',
            data
        });	
    }


    edit_contacts = (order_deal_no, contacts, del_contacts = []) => {
        let data = {
            contacts      : contacts,
            order_deal_id : order_deal_no,
            del_contacts  : del_contacts
        }

        return request({
            url: '/adm/deal/basic/edit_deal_contacts',
            method: 'post',
            data
        });	
    }


    edit_brokers = (order_deal_no, brokers = [], del_brokers =[]) => {
        let data = {
            brokers         : brokers,
            deal_id         : order_deal_no,
            delete_brokers  : del_brokers
        }

        return request({
            url: '/adm/deal/basic/edit_deal_brokers',
            method: 'post',
            data
        });	
    }
}


let _api_basic = null

const getDealBasicApi = () => {
    if (!_api_basic) {
        _api_basic = new DealBasicApi();
    }
    return _api_basic;
}

export { getDealBasicApi };